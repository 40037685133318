import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={12} md={8} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <Large to="/">
                {t("Phone: ")}
                <a href="tel:+8558878876">1-855-8UPTURN ((855) 887-8876)</a>
              </Large>

              <a href="mailto:welcome@upturn.com">
                <Chat>{t(`Email us`)}</Chat>
              </a>
            </Col>
            <Col lg={12} md={8} sm={12} xs={12}>
              <Language>{t("Address")}</Language>
              <Para>1801 Century Park East 24th Floor</Para>
              <Para>Los Angeles</Para>
              <Para>California, 90067</Para>
            </Col>
            {/* <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
              <Large to="/">{t("Support Center")}</Large>
              <Large to="/">{t("Customer Support")}</Large>
            </Col> */}
          </Row>
          {/* <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Empty />
              <Language>{t("Address")}</Language>
              <Para>1801 Century Park East 24th Floor</Para>
              <Para>Los Angeles</Para>
              <Para>California, 90067</Para>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Company")}</Title>
              <Large to="/">{t("About")}</Large>
              <Large to="/">{t("Blog")}</Large>
              <Large to="/">{t("Press")}</Large>
              <Large to="/">{t("Careers & Culture")}</Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("es")}>
                  <SvgIcon
                    src="spain.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row> */}
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <p>
              2024, Upturn USA LLC, All Rights Reserved. Please be aware that
              all calls with *Upturn USA LLC* may be recorded or monitored for
              quality assurance and training purposes. Clients who successfully
              remain in the program and settle all enrolled debts typically
              achieve savings of around 46% before fees, or 25% after fees, over
              a period of 24 to 48 months. These figures are based on past
              enrolled debts and results, which can vary depending on individual
              circumstances. Not every debt qualifies for enrollment, and not
              all clients complete the program due to various reasons, such as
              challenges in saving enough funds. We do not promise specific
              reductions in debt amounts or guarantee that you will be debt-free
              within a certain timeframe. *Upturn USA LLC* does not take over
              consumer debt, make monthly payments to creditors, provide tax,
              bankruptcy, accounting, or legal advice, or offer credit repair
              services. Services may not be available in all states. For advice
              on tax implications of debt settlement, please consult a tax
              professional. If considering bankruptcy, consult with a bankruptcy
              attorney. In some states, we may be able to recommend a local tax
              professional or bankruptcy attorney. It is important to thoroughly
              read and understand all program materials before enrolling,
              including potential negative impacts on your credit rating. This
              website uses "cookies" to enhance your browsing experience and for
              marketing and tracking purposes. By continuing to use our site,
              you consent to the use of cookies. For further details, please
              review our Terms and Privacy Policy.
            </p>
            {/* <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink> */}
            {/* <FooterContainer>
              <SocialLink
                href="https://github.com/Adrinlol/create-react-app-adrinlol"
                src="github.svg"
              />
              <SocialLink
                href="https://twitter.com/Adrinlolx"
                src="twitter.svg"
              />
              <SocialLink
                href="https://www.linkedin.com/in/lasha-kakabadze/"
                src="linkedin.svg"
              />
              <SocialLink
                href="https://medium.com/@lashakakabadze/"
                src="medium.svg"
              />
              <a
                href="https://ko-fi.com/Y8Y7H8BNJ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  height="36"
                  style={{ border: 0, height: 36 }}
                  src="https://storage.ko-fi.com/cdn/kofi3.png?v=3"
                  alt="Buy Me a Coffee at ko-fi.com"
                />
              </a>
            </FooterContainer> */}
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
